<template>
  <el-card id="changeLog">
    <header>
      <h1>坐席日志信息</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>公司ID</span>
            <el-input
              v-model="form.id"
              placeholder="请输入公司ID"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>公司名称</span>
            <el-input
              v-model="form.realname"
              placeholder="请输入公司名称"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>备注</span>
            <el-input
              v-model="form.remarks"
              placeholder="请输入备注"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>时间</span>
            <el-date-picker
              v-model="createTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="small"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            size="small"
            class="searchBtn"
            >查找</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            size="small"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userId"
          label="用户编号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="realname"
          label="用户名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="money"
          label="充值后余额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="oldMoney"
          label="充值前余额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remarks"
          label="备注信息"
          align="center"
        ></el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import axios from "axios";
import { logUserList, logUserList1 } from "@/api/log";
export default {
  data() {
    return {
      role: null,
      form: {
        id: null,
        name: "",
        oldMoney: "",
        money: "",
        createTime: "",
        remarks: null,
      },
      createTime: "",
      adminSum_Z: "admin充值",
      adminSum_F: "admin扣款",
      roleName: null,
      realname: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
      listType: 1,
      parentId: null,

    };
  },
  created() {
    this.kefuParentId = localStorage.getItem("parentId");
    this.roleName = localStorage.getItem("roleName");
    this.roleName = localStorage.getItem("roleName");
    this.parentId = localStorage.getItem("parentId");
    this.role = localStorage.getItem("role");
    this.getList();
  },
  activated() {
    this.getList();
  },
  methods: {
    // 分页
    handlePageChange(val) {
      this.page = val;
      if (2 === this.listType) {
        this.getList2();
      } else if (3 === this.listType) {
        this.getList3();
      } else {
        this.getList();
      }
    },
    handleSizeChange(val) {
      this.page = val;
      if (2 === this.listType) {
        this.getList2();
      } else if (3 === this.listType) {
        this.getList3();
      } else {
        this.getList();
      }
    },
    // 展示
    getList() {
      this.listType = 1;
      let params = {
        id: this.form.id,
        page: this.page,
        size: this.size,
        remarks: this.form.remarks,
        realname: this.form.realname,
        sta: this.sta,
        end: this.end,
      };
      if (this.kefuParentId == 3555 || this.role == "客服" || this.parentId == 2) {
        let res = logUserList1(params);
        res.then((res) => {
          if (res.data.success == false) {
            this.$router.push("/403");
          } else {
            this.tableData = res.data.data;
            this.pageTotal = res.data.total;
          }
        });
      } else {
        let res = logUserList(params);
        res.then((res) => {
          if (res.data.success == false) {
            this.$router.push("/403");
          } else {
            this.tableData = res.data.data;
            this.pageTotal = res.data.total;
          }
        });
      }
    },

    // 转换日期
    getYMDHMS(format, date) {
      // 使用方法
      // dateChangeFormat('YYYY-mm-dd HH:MM:SS', "2021-07-02T14:19:59+08:00")
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 重置
    reset() {
      this.form.realname = null;
      this.form.remarks = null;
      this.form.id = null;
      this.createTime = null;
      this.sta = null;
      this.end = null;
      this.adminSum_Z = "admin充值";
      this.adminSum_F = "admin扣款";
      this.getList();
    },
    // 搜索
    handleSearch() {
      this.page = 1;
      if (this.form.id == "") {
        this.form.id = null;
      }
      if (this.createTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createTime[1]);
      }
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
#changeLog {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>